import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { Link, Typography } from '@hbf/dsl/core';
import { Info } from '@hbf/icons/brand-regular';

import { useIntl } from 'ha/i18n';

import { resetMapAndQueryParams } from 'ha/pages/Search/actions';
import { useShouldUseMapBounds } from 'ha/pages/Search/hooks/useShouldUseMapBounds';

import { getMapViewState } from '../../../Search/selectors/mapSelectors';
import { MapViewState } from '../../../Search/types';

const useStyles = makeStyles()(theme => ({
  mapFilterNoticeContainer: {
    background: theme.palette.tertiary.accent,
    marginLeft: 'calc(var(--x-inline-padding) * -1)',
    width: 'calc(100% + (var(--x-inline-padding) * 2))',
    paddingInline: `var(--x-inline-padding)`,
    paddingBlock: theme.utils.spacing('ref/spacing/2'),

    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      paddingInline: theme.utils.spacing('ref/spacing/5'),
      width: '100%',
      borderRadius: theme.utils.borderRadius('ref/radius/sm'),
    },
  },
  mapFilterNoticeContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.utils.spacing('ref/spacing/3'),
  },
  mapFilterNoticeLabel: {
    display: 'flex',
    gap: theme.utils.spacing('ref/spacing/2'),
    alignItems: 'center',
  },
}));

export const SearchResultsMapNotification = () => {
  const { T } = useIntl();
  const { classes } = useStyles();

  const { shouldUseMap } = useShouldUseMapBounds();
  const [showNotification, setShowNotification] = React.useState(false);

  const dispatch = useDispatch();

  const mapViewState = useSelector(getMapViewState);

  React.useEffect(() => {
    const noMapInView = mapViewState === MapViewState.noMap;
    setShowNotification(shouldUseMap && noMapInView);
  }, [mapViewState, shouldUseMap]);

  const handleReset = () => {
    dispatch(resetMapAndQueryParams());
  };

  if (!showNotification) return null;

  return (
    <div className={classes.mapFilterNoticeContainer}>
      <div className={classes.mapFilterNoticeContent}>
        <span className={classes.mapFilterNoticeLabel}>
          <Info />
          <Typography variant="body/lg-regular">
            {T('search.page.banner.body')}
          </Typography>
        </span>
        <Link underline="always" size="lg" onClick={handleReset}>
          {T('search.page.banner.cta')}
        </Link>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default SearchResultsMapNotification;
